import React from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Button,
  Tooltip,
  Box,
  SvgIcon,
} from "@mui/material";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Check } from "lucide-react";

const MobileBottomBar = ({
  courseState,
  progress,
  goToNextStep,
  goToPreviousStep,
  completeTopicHandler,
  finishCourseHandler,
}) => {
  const {
    conceptCheckCorrect,
    selectedTopic,
    enrolled,
    currentChapterIndex,
    currentTopicIndex,
  } = courseState;

  return (
    <AppBar
      position="fixed"
      color="primary"
      sx={{
        top: "auto",
        bottom: 0,
        height: "60px",
        backdropFilter: "blur(20px)",
        backgroundColor: "rgb(20, 26, 33)",
        backgroundImage:
          "url(https://assets.minimals.cc/public/assets/core/cyan-blur.png), url(https://assets.minimals.cc/public/assets/core/red-blur.png)",
        backgroundRepeat: "no-repeat, no-repeat",
        backgroundPosition: "right top, left bottom",
        boxShadow: "-40px 40px 80px -8px rgba(0 0 0 / 0.24)",
        backgroundSize: "50%, 50%",
        "&::before": {
          content: '""',
          position: "absolute",
          top: -20,
          left: 0,
          right: 0,
          height: 20,
          backdropFilter: "blur(20px)",
          background:
            "linear-gradient(to bottom, transparent, rgba(20, 26, 33, 0.5))",
        },
      }}
    >
      <Toolbar>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <IconButton color="inherit" onClick={goToPreviousStep}>
            <SvgIcon focusable="false" ariaHidden="true" viewBox="0 0 24 24">
              <path
                fill="currentColor"
                fillRule="evenodd"
                d="M15.488 4.43a.75.75 0 0 1 .081 1.058L9.988 12l5.581 6.512a.75.75 0 1 1-1.138.976l-6-7a.75.75 0 0 1 0-.976l6-7a.75.75 0 0 1 1.057-.081"
                clipRule="evenodd"
              ></path>
            </SvgIcon>
          </IconButton>
          <Tooltip
            title={
              !enrolled
                ? "Please enroll in course to complete"
                : !selectedTopic?.conceptChecks.every(
                    (_, index) => conceptCheckCorrect[index]
                  )
                ? "Make sure all questions are answered correctly"
                  ? progress?.chapters?.[currentChapterIndex]?.topics?.[
                      currentTopicIndex
                    ]?.completed
                  : "Topic already completed"
                : ""
            }
          >
            <span>
              <LoadingButton
                loading={false}
                loadingPosition="start"
                variant="contained"
                color="primary"
                startIcon={<Check />}
                disabled={
                  !courseState.enrolled ||
                  !selectedTopic?.conceptChecks.every(
                    (_, index) => courseState.conceptCheckCorrect[index]
                  ) ||
                  courseState.progress?.chapters?.[
                    courseState.currentChapterIndex
                  ]?.topics?.[courseState.currentTopicIndex]?.completed
                }
                onClick={() =>
                  courseState.isLastStep &&
                  courseState.progress?.chapters?.every(chapter =>
                    chapter.topics.every(topic => topic.completed)
                  )
                    ? finishCourseHandler()
                    : completeTopicHandler(
                        courseState.currentChapterIndex,
                        courseState.currentTopicIndex
                      )
                }
              >
                {courseState.isLastStep &&
                courseState.progress?.chapters?.every(chapter =>
                  chapter.topics.every(topic => topic.completed)
                )
                  ? "Finish Course"
                  : "Complete Topic"}
              </LoadingButton>
            </span>
          </Tooltip>
          <IconButton sx={{ color: "white" }} onClick={goToNextStep}>
            <SvgIcon focusable="false" ariaHidden="true" viewBox="0 0 24 24">
              <path
                fill="currentColor"
                fillRule="evenodd"
                d="M8.512 4.43a.75.75 0 0 1 1.057.082l6 7a.75.75 0 0 1 0 .976l-6 7a.75.75 0 0 1-1.138-.976L14.012 12L8.431 5.488a.75.75 0 0 1 .08-1.057"
                clipRule="evenodd"
              ></path>
            </SvgIcon>
          </IconButton>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default MobileBottomBar;
