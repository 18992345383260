import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useStopwatch } from "react-timer-hook";
import { toast } from "react-toastify";
import { setCurrentQuiz } from "../../../actions/quiz";
import {
  getQuizById,
  getUserQuizProgress,
  updateStudies,
} from "../../../services/quiz.service";
import QuizContent from "./QuizContent";
import QuizResults from "./QuizResults";
import ExplanationModal from "./ExplanationModal";
import {
  CircularProgress,
  LinearProgress,
  linearProgressClasses,
} from "@mui/material";

const Quiz = () => {
  const dispatch = useDispatch();
  const { quizId } = useParams();
  const { user } = useSelector(state => state.auth);
  const { currentQuiz } = useSelector(state => state.quiz);
  const { totalSeconds, pause, reset } = useStopwatch({ autoStart: true });

  const [loading, setLoading] = useState(true);
  const [quizState, setQuizState] = useState({
    selectedAnswers: [],
    currentQuestionIndex: 0,
    submitted: false,
    finished: false,
    clickedButtonIndex: null,
    zoomOut: false,
    score: 0,
  });
  const [isExplanationModalOpen, setIsExplanationModalOpen] = useState(false);
  const [explanations, setExplanations] = useState([]);
  const [results, setResults] = useState(null);
  const [userProgress, setUserProgress] = useState(null);

  const fetchQuizAndProgress = useCallback(async () => {
    try {
      setLoading(true);
      const quizData = await getQuizById(quizId);
      dispatch(setCurrentQuiz(quizData.quiz));

      if (user) {
        const progressData = await getUserQuizProgress(quizId, user.id);
        const isRetaking =
          localStorage.getItem(`quiz_${quizId}_retake`) === "true";
        const length = progressData.userProgress.length - 1;

        if (progressData.userProgress.length > 0 && !isRetaking) {
          setQuizState(prev => ({ ...prev, submitted: true }));
          setResults({
            selectedAnswers: progressData.userProgress[length].questions,
            timeTaken: progressData.userProgress[length].timeTaken,
            score: progressData.userProgress[length].score,
          });
          setUserProgress(progressData.userProgress[length]);
        } else {
          localStorage.removeItem(`quiz_${quizId}_retake`);
        }
      }
    } catch (error) {
      console.error("Error fetching quiz:", error);
      toast.error("Failed to load quiz data");
    } finally {
      setLoading(false);
    }
  }, [quizId, user, dispatch]);

  useEffect(() => {
    fetchQuizAndProgress();
  }, [fetchQuizAndProgress]);

  useEffect(() => {
    if (quizState.submitted) {
      pause();

      const fetchExplanations = async () => {
        try {
          const quizData = await getQuizById(quizId);
          setExplanations(quizData.quiz.explanations);
        } catch (err) {
          console.error("Error fetching explanations:", err);
          toast.error("Failed to load explanations");
        }
      };
      fetchExplanations();
    }
  }, [quizState.submitted, pause, quizId]);

  const updateQuestions = useCallback(async () => {
    try {
      const response = await updateStudies({
        quizId,
        userId: user.id,
        questions: quizState.selectedAnswers,
        timeTaken: totalSeconds,
        score: quizState.score,
      });
      setUserProgress(response.userProgress);
      setResults({
        selectedAnswers: quizState.selectedAnswers,
        timeTaken: totalSeconds,
        score: quizState.score,
      });
    } catch (error) {
      console.error("Error submitting answers:", error);
      toast.error("Failed to submit answers");
    }
  }, [
    quizId,
    user.id,
    quizState.score,
    quizState.selectedAnswers,
    totalSeconds,
  ]);

  useEffect(() => {
    if (quizState.finished) {
      localStorage.removeItem(`quiz_${quizId}_retake`);

      updateQuestions();
    }
  }, [
    quizState.finished,
    quizId,
    user.id,
    totalSeconds,
    quizState.selectedAnswers,
    quizState.score,
    updateQuestions,
  ]);

  const handleAnswerClick = useCallback(
    (option, index) => {
      const question = currentQuiz.questions[quizState.currentQuestionIndex];
      const isCorrect = option === question.answer;

      setQuizState(prev => {
        const newState = {
          ...prev,
          selectedAnswers: [
            ...prev.selectedAnswers,
            {
              questionId: question._id,
              question: question.question,
              correctAnswer: question.answer,
              option,
              isCorrect,
            },
          ],
          score: prev.score + (isCorrect ? 1 : 0),
          clickedButtonIndex: index,
          zoomOut: true,
        };

        localStorage.setItem(`quiz_${quizId}_state`, JSON.stringify(newState));
        return newState;
      });

      setTimeout(() => {
        setQuizState(prev => ({
          ...prev,
          currentQuestionIndex:
            prev.currentQuestionIndex < currentQuiz.questions.length - 1
              ? prev.currentQuestionIndex + 1
              : prev.currentQuestionIndex,
          clickedButtonIndex: null,
          zoomOut: false,
          submitted:
            prev.currentQuestionIndex === currentQuiz.questions.length - 1,
          finished:
            prev.currentQuestionIndex === currentQuiz.questions.length - 1,
        }));
      }, 1000);
    },
    [currentQuiz, quizId, quizState.currentQuestionIndex]
  );

  const handleResetProgress = async () => {
    localStorage.setItem(`quiz_${quizId}_retake`, "true");

    try {
      localStorage.removeItem(`quiz_${quizId}_state`);
      setQuizState({
        selectedAnswers: [],
        currentQuestionIndex: 0,
        submitted: false,
        finished: false,
        clickedButtonIndex: null,
        zoomOut: false,
        score: 0,
      });
      setResults(null);
      reset();
    } catch (error) {
      console.error("Error resetting quiz progress:", error);
    }
  };

  return (
    <>
      {loading ? (
        <LinearProgress
          sx={{
            width: "100%",
            height: 4,
            [`& .${linearProgressClasses.bar}`]: {
              borderRadius: 0,
              backgroundColor: "rgb(0, 167, 111)",
            },
          }}
        />
      ) : (
        <>
          {currentQuiz && !quizState.submitted && (
            <QuizContent
              currentQuiz={currentQuiz}
              quizState={quizState}
              totalSeconds={totalSeconds}
              handleAnswerClick={handleAnswerClick}
            />
          )}
          {quizState.submitted && results && (
            <QuizResults
              explanations={explanations}
              setExplanations={setExplanations}
              setIsExplanationModalOpen={setIsExplanationModalOpen}
              quizId={quizId}
              currentQuiz={currentQuiz}
              questions={currentQuiz.questions}
              results={results}
              timer={totalSeconds}
              userProgress={userProgress}
              handleReset={handleResetProgress}
            />
          )}
          <ExplanationModal
            isOpen={isExplanationModalOpen}
            handleClose={() => setIsExplanationModalOpen(false)}
            explanations={explanations}
            questions={currentQuiz.questions}
            results={results}
          />
        </>
      )}
    </>
  );
};

export default Quiz;
