import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Card,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { BsTwitter, BsYoutube } from "react-icons/bs";
import { FaFacebookF } from "react-icons/fa";
import { FiArrowRight } from "react-icons/fi";
import { SiLinkedin } from "react-icons/si";
import { useNavigate } from "react-router-dom";
import Navbar from "../navbar/Navbar";
import { useSpring, animated } from "react-spring";
import { motion } from "framer-motion";
import AOS from "aos";
import "aos/dist/aos.css";
import AboutBackgroundImage from "./Assets/about-background-image.png";
import AboutBackground from "./Assets/about-background.png";
import ChooseMeals from "./Assets/choose-image.png";
import DeliveryMeals from "./Assets/delivery-image.png";
import BannerImage from "./Assets/home-banner-image.png";
import PickMeals from "./Assets/pick-meals-image.png";
import logo from "../images/homePicture.png";
import growX from "../images/growx-logo.png";
import courseImage from "../images/courseImage.png";

import "./Home.css";
import { toast } from "react-toastify";

const Home = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [error, setError] = useState(false);
  const [users, setUsers] = useState(360);
  const [fail, setFail] = useState(false);
  const handleChange = e => {
    const value = e.target.value;
    setEmail(value);

    // Simple email validation regex
    const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
    setError(!isValidEmail);
  };

  const handleWaitlist = () => {
    if (error || email == "") {
      toast.error("Please enter a valid email address.");
    } else {
      toast.success(
        "Thank you for joining the waitlist! We'll notify you when we launch."
      );
      setEmail("");
    }
  };

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);

  const fadeIn = useSpring({
    opacity: 1,
    from: { opacity: 0 },
    config: { duration: 1000 },
  });

  const workInfoData = [
    {
      image: PickMeals,
      title: "Create AI Studysets",
      text: "Easily generate study sets with AI to enhance your learning experience. Tailored content just for you.",
    },
    {
      image: ChooseMeals,
      title: "Study with Flashcards and Quizzes",
      text: "Engage with interactive flashcards and quizzes to test your knowledge and reinforce learning.",
    },
    {
      image: DeliveryMeals,
      title: "AI Generate Courses",
      text: "Let AI craft comprehensive courses for you, covering all essential topics in an organized manner.",
    },
  ];

  return (
    <Box
      sx={{
        px: 5,
      }}
    >
      <animated.div className="home-container">
        <Navbar />
        <motion.div className="home-banner-container">
          <div className="home-bannerImage-container">
            <img src={BannerImage} alt="" style={{ marginTop: -15 }} />
          </div>
          <div className="home-text-section">
            <h1 className="primary-heading">
              Your Number One Platform to Grow
            </h1>
            <p className="primary-text">
              Join the thousands of users waiting to get access to a platform
              that are improving all aspects of their lives on GrowX.
            </p>
            <Box>
              <Box
                gap={1.5}
                display="flex"
                flexWrap="wrap"
                alignItems="center"
                justifyContent="center"
                sx={{ typography: "subtitle2" }}
              >
                <AvatarGroup
                // sx={{ [`& .${avatarClasses.root}`]: { width: 32, height: 32 } }}
                >
                  <Avatar
                    src={
                      "https://assets.minimals.cc/public/assets/images/mock/avatar/avatar-2.webp"
                    }
                  />
                  <Avatar
                    src={
                      "https://assets.minimals.cc/public/assets/images/mock/avatar/avatar-3.webp"
                    }
                  />
                  <Avatar
                    src={
                      "https://assets.minimals.cc/public/assets/images/mock/avatar/avatar-4.webp"
                    }
                  />
                </AvatarGroup>
                {Math.floor(users / 10)}0+ users joined
              </Box>

              <Box sx={{ display: "flex", alignItems: "center", mt: 3 }}>
                {/* <TextField
                sx={{
                  borderTopLeftRadius: 10,
                  width: "20vw",
                  borderBottomLeftRadius: 10,
                  "& fieldset": {
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                  },
                  "& .MuiOutlinedInput-root": {
                    height: "56px", // Match the button height
                  },
                  "& .MuiFormHelperText-root": {
                    position: "absolute",
                    bottom: "-20px", // Adjust positioning of the helper text
                  },
                }}
                placeholder="Email"
                type="email"
                value={email}
                onChange={handleChange}
                error={error}
                helperText={error ? "Please enter a valid email" : ""}
              /> */}
                <button
                  className="primary-button"
                  onClick={() => navigate("/register")}
                >
                  Sign Up Now
                </button>
              </Box>
            </Box>
          </div>
          <div className="home-image-section">
            <img src={logo} alt="logo" />
          </div>
        </motion.div>
      </animated.div>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          className="about-section-container"
          data-aos="fade-up"
          style={{
            display: "flex",
            alignItems: "center",
            // paddingLeft: 10,
            // paddingRight: 10,
          }}
        >
          <div className="">
            <img src={courseImage} alt="" style={{}} />
          </div>
          <div
            className=""
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <p className="primary-subheading">Why Us</p>
            <h1 className="primary-heading">Learning Is Vital</h1>
            <p className="primary-text">
              Our platform harnesses the power of AI to transform the way you
              learn, offering a personalized and engaging educational journey.
              We aim to make learning accessible, enjoyable, and tailored to
              each individual's unique needs and preferences.
            </p>
            <p className="primary-text">
              By integrating advanced technology, we strive to create an
              environment where knowledge is easily attainable, fostering a
              culture of continuous growth and development for learners of all
              ages and backgrounds.
            </p>
          </div>
        </div>
      </Box>
      <div className="work-section-wrapper" data-aos="fade-up">
        <div className="work-section-top">
          <p className="primary-subheading">Features</p>
          <h1 className="primary-heading">How It Works</h1>
          <p className="primary-text">
            GrowX provides you with a multitude of AI based features to give you
            an enjoyable and personalized experience for your learning journey.
          </p>
        </div>
        <div className="work-section-bottom">
          {workInfoData.map(data => (
            <Card
              className="work-section-info"
              key={data.title}
              sx={{ cursor: "pointer" }}
            >
              <div className="info-boxes-img-container">
                <img src={data.image} alt="" />
              </div>
              <h2>{data.title}</h2>
              <p>{data.text}</p>
            </Card>
          ))}
        </div>
      </div>
      <div className="footer-wrapper">
        <div className="footer-section-one">
          <div className="footer-logo-container">
            <img src={growX} />
          </div>
          <div className="footer-icons">
            <BsTwitter />
            <SiLinkedin />
            <BsYoutube />
            <FaFacebookF />
          </div>
        </div>
        <div className="footer-section-two">
          <div className="footer-section-columns">
            <span>Qualtiy</span>
            <span>Help</span>
            <span>Share</span>
            <span>Carrers</span>
            <span>Testimonials</span>
            <span>Work</span>
          </div>
          <div className="footer-section-columns">
            <span>244-5333-7783</span>
            <span>hello@food.com</span>
            <span>press@food.com</span>
            <span>contact@food.com</span>
          </div>
          <div className="footer-section-columns">
            <span>Terms & Conditions</span>
            <span>Privacy Policy</span>
          </div>
        </div>
      </div>
    </Box>
  );
};

export default Home;
