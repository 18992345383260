import { Icon } from "@iconify/react/dist/iconify.js";
import {
  AutoAwesome,
  CalendarMonth,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  Collapse,
  Container,
  FormControl,
  Grid,
  Grow,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Skeleton,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { ListOrdered, Puzzle, School } from "lucide-react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import noImage from "../../images/noImage.png";
import { getQuizById, getUserQuizProgress } from "../../services/quiz.service";
import { getUserById } from "../../services/user.service";

const SkeletonLoader = () => (
  <Container>
    <Box sx={{ mb: 7 }}>
      <Skeleton
        variant="rectangular"
        width="100%"
        height={300}
        sx={{ borderRadius: "16px", mb: 4 }}
      />
      <Typography variant="h3">
        <Skeleton type="text" width="50%" />
      </Typography>
      <Typography>
        <Skeleton type="text" width="80%" />
      </Typography>
    </Box>
    <Grid container spacing={4}>
      <Grid item xs={12} md={4}>
        <Card>
          <CardContent>
            <Typography variant="body2">
              <Skeleton variant="text" width="40%" sx={{ mb: 1 }} />
            </Typography>
            <Typography>
              <Skeleton
                variant="text"
                width="90%"
                sx={{ mb: 3, fontSize: "0.875rem" }}
              />
            </Typography>
            <Typography variant="body2">
              <Skeleton variant="text" width="40%" sx={{ mb: 1 }} />
            </Typography>
            <Typography>
              <Skeleton
                variant="text"
                width="90%"
                sx={{ mb: 3, fontSize: "0.875rem" }}
              />
            </Typography>
            <Typography variant="body2">
              <Skeleton variant="text" width="40%" sx={{ mb: 1 }} />
            </Typography>
            <Typography>
              <Skeleton
                variant="text"
                width="90%"
                sx={{ mb: 3, fontSize: "0.875rem" }}
              />
            </Typography>
            <Typography variant="body2">
              <Skeleton variant="text" width="40%" sx={{ mb: 1 }} />
            </Typography>
            <Typography>
              <Skeleton
                variant="text"
                width="90%"
                sx={{ mb: 3, fontSize: "0.875rem" }}
              />
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={8}>
        <Skeleton
          variant="rectangular"
          width="100%"
          height={400}
          sx={{ borderRadius: "16px", mb: 3 }}
        />
      </Grid>
    </Grid>
  </Container>
);

const ViewStudyset = () => {
  const theme = useTheme();
  const { studysetId } = useParams();
  const { user } = useSelector(state => state.auth);
  const [studysetUser, setStudysetUser] = useState("");
  const [questionOpenStates, setQuestionOpenStates] = useState({});
  const [quizAttempts, setQuizAttempts] = useState([]);
  const [studyset, setStudyset] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const loadStudyset = async () => {
      setLoading(true);

      try {
        const finalStudyset = await getQuizById(studysetId);
        const studysetCreator = await getUserById(finalStudyset.quiz.user);
        const userProgressResponse = await getUserQuizProgress(
          studysetId,
          user.id
        );

        setStudyset(finalStudyset?.quiz);
        setStudysetUser(studysetCreator?.data.user);

        if (userProgressResponse && userProgressResponse.userProgress) {
          console.log("SAHIOFJADSIFD", userProgressResponse.userProgress);
          setQuizAttempts(userProgressResponse.userProgress);
        }

        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    loadStudyset();
  }, [studysetId, user.id]);

  const handleCollapseToggle = index => {
    setQuestionOpenStates(prevStates => ({
      ...prevStates,
      [index]: !prevStates[index],
    }));
  };

  return (
    <Container>
      {loading ? (
        <SkeletonLoader />
      ) : (
        <div>
          {studyset.length !== 0 && (
            <Box sx={{ px: 2 }}>
              <Box sx={{ position: "relative", mb: 3 }}>
                <img
                  src={studyset?.imageUrl || noImage}
                  alt="Studyset Cover"
                  style={{
                    width: "100%",
                    height: "300px",
                    objectFit: "cover",
                    borderRadius: "16px",
                    filter: "brightness(60%)",
                  }}
                />
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    mt: 3,
                  }}
                >
                  <div>
                    <Box sx={{ display: "flex" }}>
                      <AutoAwesome
                        sx={{
                          fontSize: 20,
                          marginRight: 1,
                          color: "#00a76f",
                          fontWeight: 800,
                        }}
                      />
                      <Typography
                        variant="body2"
                        sx={{
                          color: "#00a76f",
                          fontWeight: 800,
                        }}
                      >
                        {studyset?.subject?.toUpperCase()}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Typography
                        variant="h4"
                        fontWeight={600}
                        sx={{ color: "white", mt: 1 }}
                        gutterBottom
                      >
                        {studyset.title}
                      </Typography>
                      <Typography color="text.secondary">
                        {studyset.description}
                      </Typography>
                    </Box>
                  </div>

                  <div>
                    <Button
                      variant="contained"
                      sx={{ padding: 0, alignSelf: "center", width: "100%" }}
                    >
                      <FormControl size="small">
                        <Select
                          displayEmpty
                          sx={{
                            "& fieldset": { border: "none" },
                          }}
                          renderValue={() => (
                            <Box
                              sx={{ mr: "6px", fontWeight: 700 }}
                              fontSize="14px"
                            >
                              Study With
                            </Box>
                          )}
                          IconComponent={KeyboardArrowDown}
                        >
                          <MenuItem value="Quizzes">
                            <Box
                              sx={{
                                display: "flex",
                                fontWeight: 600,
                                gap: 1,
                              }}
                              onClick={() => navigate(`/quiz/${studyset._id}`)}
                            >
                              <Icon
                                icon="solar:document-add-bold"
                                width="24"
                                height="24"
                              />{" "}
                              Quizzes
                            </Box>
                          </MenuItem>
                          <MenuItem value="Flashcards">
                            <Box
                              sx={{
                                display: "flex",
                                fontWeight: 600,
                                gap: 1,
                              }}
                              onClick={() =>
                                navigate(`/flashcard/${studyset._id}`)
                              }
                            >
                              <Icon
                                icon="solar:documents-bold"
                                width="24"
                                height="24"
                              />{" "}
                              Flashcards
                            </Box>
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Button>
                  </div>
                </Box>
              </Box>
              <Grid container spacing={4}>
                <Grid item xs={12} md={4}>
                  <Grow in>
                    <Card>
                      <CardContent>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              mb: "4px",
                            }}
                          >
                            <ListOrdered />

                            <Typography
                              variant="body2"
                              fontSize="0.875rem"
                              color="#919EAB"
                              sx={{ ml: 1 }}
                            >
                              Number of Terms
                            </Typography>
                          </Box>

                          <Typography
                            variant="body2"
                            sx={{
                              fontWeight: 600,
                              fontSize: "0.875rem",
                              ml: 4,
                              mb: 2,
                            }}
                          >
                            {studyset.questions.length} terms
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              mb: "4px",
                            }}
                          >
                            <Puzzle />

                            <Typography
                              variant="body2"
                              fontSize="0.875rem"
                              color="#919EAB"
                              sx={{ ml: 1 }}
                            >
                              Difficulty
                            </Typography>
                          </Box>

                          <Typography
                            variant="body2"
                            sx={{
                              fontWeight: 600,
                              fontSize: "0.875rem",
                              ml: 4,
                              mb: 2,
                              textTransform: "capitalize",
                            }}
                          >
                            {studyset.difficulty}
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              mb: "4px",
                            }}
                          >
                            <School />

                            <Typography
                              variant="body2"
                              fontSize="0.875rem"
                              color="#919EAB"
                              sx={{ ml: 1 }}
                            >
                              Level
                            </Typography>
                          </Box>

                          <Typography
                            variant="body2"
                            sx={{
                              fontWeight: 600,
                              fontSize: "0.875rem",
                              ml: 4,
                              mb: 2,
                            }}
                          >
                            {studyset.gradeLevel}
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              mb: "4px",
                            }}
                          >
                            <CalendarMonth />

                            <Typography
                              variant="body2"
                              fontSize="0.875rem"
                              color="#919EAB"
                              sx={{ ml: 1 }}
                            >
                              Date Posted
                            </Typography>
                          </Box>

                          <Typography
                            variant="body2"
                            sx={{
                              fontWeight: 600,
                              fontSize: "0.875rem",
                              ml: 4,
                              mb: 2,
                            }}
                          >
                            {moment(studyset.createdAt).format("MMMM Do, YYYY")}
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              mb: "4px",
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              class="lucide lucide-user-pen"
                            >
                              <path d="M11.5 15H7a4 4 0 0 0-4 4v2" />
                              <path d="M21.378 16.626a1 1 0 0 0-3.004-3.004l-4.01 4.012a2 2 0 0 0-.506.854l-.837 2.87a.5.5 0 0 0 .62.62l2.87-.837a2 2 0 0 0 .854-.506z" />
                              <circle cx="10" cy="7" r="4" />
                            </svg>

                            <Typography
                              variant="body2"
                              fontSize="0.875rem"
                              color="#919EAB"
                              sx={{ ml: 1 }}
                            >
                              Created by
                            </Typography>
                          </Box>

                          <Typography
                            variant="body2"
                            sx={{
                              fontWeight: 600,
                              fontSize: "0.875rem",
                              ml: 4,
                              mb: 2,
                            }}
                          >
                            {studysetUser?.username}
                          </Typography>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grow>
                </Grid>
                <Grid item xs={12} md={8}>
                  <Grow in>
                    <Paper
                      sx={{
                        borderRadius: 4,
                        boxShadow:
                          "rgba(0, 0, 0, 0.2) 0px 0px 2px 0px, rgba(0, 0, 0, 0.12) 0px 8px 20px -4px",
                        backgroundImage: "none",
                      }}
                    >
                      <Tabs
                        value={1}
                        textColor="inherit"
                        TabIndicatorProps={{
                          children: <span className="MuiTabs-indicatorSpan" />,
                        }}
                        sx={{
                          px: 1,
                          boxShadow:
                            "inset 0 -2px 0 0 rgba(145 158 171 / 0.08)",
                          "& .MuiTabs-indicator": {
                            display: "flex",
                            justifyContent: "center",
                            backgroundColor: "transparent",
                          },
                          "& .MuiTabs-indicatorSpan": {
                            maxWidth: "90%",
                            width: "100%",
                            backgroundColor: "white",
                          },
                        }}
                      >
                        <Tab
                          label="Results"
                          iconPosition="end"
                          disableRipple
                          sx={{ minHeight: "48px", minWidth: 0 }}
                          icon={
                            <Box
                              sx={{
                                height: "24px",
                                minWidth: "24px",
                                bgcolor: "white",
                                color: "background.paper",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                borderRadius: "6px",
                                fontSize: "0.75rem",
                                fontWeight: 700,
                                px: "6px",
                              }}
                            >
                              {quizAttempts?.length}
                            </Box>
                          }
                        />
                      </Tabs>

                      <Table>
                        <TableHead sx={{ borderBottom: "none" }}>
                          <TableRow sx={{ bgcolor: "#28323D" }}>
                            <TableCell sx={{ borderBottom: 0 }} />
                            <TableCell
                              align="left"
                              sx={{
                                color: "#919EAB",
                                fontWeight: 600,
                                fontSize: "16px",
                                borderBottom: 0,
                              }}
                            >
                              Date Taken
                            </TableCell>
                            {!isMobile && (
                              <>
                                <TableCell
                                  align="left"
                                  sx={{
                                    color: "#919EAB",
                                    fontWeight: 600,
                                    fontSize: "16px",
                                    borderBottom: 0,
                                  }}
                                >
                                  Score
                                </TableCell>
                                <TableCell
                                  align="left"
                                  sx={{
                                    color: "#919EAB",
                                    fontWeight: 600,
                                    fontSize: "16px",
                                    borderBottom: 0,
                                  }}
                                >
                                  Accuracy
                                </TableCell>
                              </>
                            )}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {quizAttempts?.map((attempt, index) => (
                            <React.Fragment key={index}>
                              <TableRow
                                sx={{
                                  "&:hover": {
                                    bgcolor: "rgba(145 158 171 / 0.04)",
                                  },
                                }}
                              >
                                <TableCell
                                  sx={{
                                    padding: theme =>
                                      theme.spacing(0, 0, 0, 1.5),
                                  }}
                                >
                                  <IconButton
                                    size="small"
                                    onClick={() => handleCollapseToggle(index)}
                                  >
                                    <KeyboardArrowUp
                                      sx={{
                                        transform: questionOpenStates[index]
                                          ? "rotate(0deg)"
                                          : "rotate(180deg)",
                                        transition: theme =>
                                          theme.transitions.create("transform"),
                                      }}
                                    />
                                  </IconButton>
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  <Tooltip
                                    title={moment(attempt.completedAt).format(
                                      "LLL"
                                    )}
                                  >
                                    <Typography variant="h6">
                                      {moment(attempt.completedAt).format(
                                        "MMM Do YYYY"
                                      )}
                                      {isMobile && (
                                        <Typography
                                          variant="body2"
                                          color="text.secondary"
                                        >
                                          Score: {attempt.score} /{" "}
                                          {attempt.questions.length} (
                                          {(
                                            (attempt.score /
                                              attempt.questions.length) *
                                            100
                                          ).toFixed(2)}
                                          %)
                                        </Typography>
                                      )}
                                    </Typography>
                                  </Tooltip>
                                  <Typography
                                    variant="subtitle2"
                                    color="text.secondary"
                                  >
                                    Result No. {index + 1}
                                  </Typography>
                                </TableCell>
                                {!isMobile && (
                                  <>
                                    <TableCell align="left">
                                      {attempt.score} /{" "}
                                      {attempt.questions.length}
                                    </TableCell>
                                    <TableCell>
                                      {(
                                        (attempt.score /
                                          attempt.questions.length) *
                                        100
                                      ).toFixed(2)}
                                      %
                                    </TableCell>
                                  </>
                                )}
                              </TableRow>
                              <TableRow
                                sx={{
                                  bgcolor: "#28323D",
                                  backgroundImage: "none",
                                }}
                              >
                                <TableCell
                                  style={{
                                    paddingTop: 0,
                                    paddingBottom: 0,
                                    borderBottom: "none",
                                  }}
                                  colSpan={6}
                                >
                                  <Collapse
                                    in={questionOpenStates[index]}
                                    timeout="auto"
                                    unmountOnExit
                                  >
                                    <Paper
                                      sx={{
                                        margin: "12px",
                                        backgroundImage: "none",
                                        boxShadow: "none",
                                        borderRadius: 2,
                                      }}
                                    >
                                      <Table size="small">
                                        <TableHead>
                                          <TableRow>
                                            <TableCell>Question</TableCell>
                                            <TableCell>Your Answer</TableCell>
                                            {!isMobile && (
                                              <TableCell>
                                                Correct Answer
                                              </TableCell>
                                            )}
                                          </TableRow>
                                        </TableHead>
                                        <TableBody
                                          sx={{
                                            p: "12px 16px 12px 12px",
                                          }}
                                        >
                                          {attempt.questions.map(
                                            (q, qIndex) => (
                                              <React.Fragment key={qIndex}>
                                                <TableRow
                                                  sx={{
                                                    "& .MuiTableRow-root": {
                                                      py: 2,
                                                    },
                                                  }}
                                                >
                                                  <TableCell
                                                    sx={{ borderBottom: 0 }}
                                                    scope="row"
                                                  >
                                                    <Box
                                                      sx={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        gap: 2,
                                                      }}
                                                    >
                                                      <Typography>
                                                        {qIndex + 1}.{" "}
                                                      </Typography>
                                                      <Typography>
                                                        {q.question}
                                                      </Typography>
                                                    </Box>
                                                  </TableCell>
                                                  <TableCell
                                                    sx={{ borderBottom: 0 }}
                                                  >
                                                    <Typography
                                                      fontSize="14px"
                                                      color={
                                                        q.isCorrect
                                                          ? "success.main"
                                                          : "error.main"
                                                      }
                                                    >
                                                      {q.option}
                                                    </Typography>
                                                    {isMobile && (
                                                      <Typography
                                                        variant="body2"
                                                        color="text.secondary"
                                                      >
                                                        Correct:{" "}
                                                        {q.correctAnswer}
                                                      </Typography>
                                                    )}
                                                  </TableCell>
                                                  {!isMobile && (
                                                    <TableCell
                                                      sx={{ borderBottom: 0 }}
                                                    >
                                                      {q.correctAnswer}
                                                    </TableCell>
                                                  )}
                                                </TableRow>
                                                {qIndex <
                                                  attempt.questions.length -
                                                    1 && (
                                                  <TableCell
                                                    colSpan={4}
                                                    sx={{
                                                      p: 0,
                                                    }}
                                                  />
                                                )}
                                              </React.Fragment>
                                            )
                                          )}
                                        </TableBody>
                                      </Table>
                                    </Paper>
                                  </Collapse>
                                </TableCell>
                              </TableRow>
                            </React.Fragment>
                          ))}
                          {!quizAttempts.length && (
                            <TableRow>
                              <TableCell colSpan={4} sx={{ borderBottom: 0 }}>
                                <Grow in>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      flexDirection: "column",
                                      borderRadius: "16px",
                                      padding: theme => theme.spacing(4, 3),
                                      border:
                                        "1px dashed rgba(145 158 171 / 0.08)",
                                      bgcolor: "rgba(145 158 171 / 0.04)",
                                      m: 1,
                                    }}
                                  >
                                    <img
                                      src={
                                        "https://pub-c5e31b5cdafb419fb247a8ac2e78df7a.r2.dev/public/assets/icons/empty/ic-content.svg"
                                      }
                                      alt="No results found for AI generated quizzes that you searched for"
                                      width={160}
                                      height={160}
                                    />
                                    <Typography
                                      color="#637381"
                                      fontWeight={600}
                                      fontSize="18px"
                                      sx={{ mt: 1 }}
                                    >
                                      No Results Found
                                    </Typography>
                                  </Box>
                                </Grow>
                              </TableCell>
                            </TableRow>
                          )}
                          {quizAttempts.length > 0 && (
                            <TableRow>
                              <TableCell
                                sx={{ borderBottom: 0, height: "48px" }}
                              />
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </Paper>
                  </Grow>
                </Grid>
              </Grid>
            </Box>
          )}
        </div>
      )}
    </Container>
  );
};

export default ViewStudyset;
