import { Icon } from "@iconify/react/dist/iconify.js";
import { MoreVert } from "@mui/icons-material";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  // CircularProgress,
  Grow,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
  useMediaQuery,
  // Zoom,
} from "@mui/material";
import { CopyCheck } from "lucide-react";
import moment from "moment/moment";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import noImage from "../../../images/noImage.png";

export default function QuizCard({
  filteredQuiz,
  // handleDeleteQuiz,
  handleShareClick,
}) {
  // const [delLoading, setDelLoading] = useState(false);
  // const [confirmDelete, setConfirmDelete] = useState(null);
  const [anchorEl, setAnchorEl] = useState(false);
  const navigate = useNavigate();

  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));

  // const handleDelete = quizId => {
  //   if (confirmDelete === quizId) {
  //     setDelLoading(true);

  //     try {
  //       handleDeleteQuiz(quizId);
  //       setDelLoading(false);
  //       setConfirmDelete(null);
  //       window.location.reload();
  //     } catch (error) {
  //       console.error(error);
  //       setDelLoading(false);
  //       setConfirmDelete(null);
  //     }
  //   } else {
  //     setConfirmDelete(quizId);
  //   }
  // };

  return (
    <>
      <Grow in>
        <Card
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            // position: "relative",
          }}
        >
          <CardMedia
            component="img"
            image={filteredQuiz.imageUrl || noImage}
            alt={filteredQuiz.description}
            sx={{
              width: "auto",
              margin: theme => theme.spacing(1, 1, 0, 1),
              borderRadius: 2,
              height: 151,
              filter: "brightness(60%)",
            }}
          />
          {/* <Box
            sx={{
              position: "absolute",
              padding: "4px 6px",
              borderRadius: 2,
              top: "20px",
              left: "20px",
              display: "flex",
              alignItems: "center",
              gap: 1,
              color: "primary.contrastText",
              backgroundColor: "background.paper",
              boxShadow:
                "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
            }}
          >
            <Tooltip title="Multiple Choice Quiz">
              <>
                <CopyCheck size={20} /> {filteredQuiz.questions.length} term
                {filteredQuiz.questions.length > 1 ? "s" : ""}
              </>
            </Tooltip>
          </Box> */}
          <CardContent
            sx={{
              flexGrow: 1,
              display: "flex",
              flexDirection: "column",
              mb: -2,
            }}
          >
            <Typography
              variant="body2"
              color="#637381"
              gutterBottom
              fontSize="14px"
            >
              Created {moment(filteredQuiz.createdAt).fromNow()}
            </Typography>
            <Typography
              onClick={() => navigate(`/studyset/${filteredQuiz._id}`)}
              variant="h6"
              noWrap
              gutterBottom
              sx={{
                fontWeight: 600,
                cursor: "pointer",
                textTransform: "capitalize",
                textOverflow: "ellipsis",
                "&:hover": {
                  textDecoration: "underline",
                },
              }}
              fontSize={isSmallScreen ? "16px" : "18px"}
            >
              {filteredQuiz.title}
            </Typography>

            <Typography
              color="text.secondary"
              component="div"
              sx={{
                wordBreak: "break-word",
                overflow: "hidden",
                display: "-webkit-box",
                WebkitLineClamp: 2,
                WebkitBoxOrient: "vertical",
              }}
            >
              {filteredQuiz.description}
            </Typography>
          </CardContent>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              padding: theme =>
                isSmallScreen ? theme.spacing(0, 1, 2) : theme.spacing(0, 2, 3),
            }}
          >
            <Tooltip title="More" arrow>
              <IconButton
                onClick={e => setAnchorEl(e.currentTarget)}
                size="small"
                edge="end"
              >
                <MoreVert />
              </IconButton>
            </Tooltip>
          </Box>
        </Card>
      </Grow>
      <Menu
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
          // setConfirmDelete(false);
        }}
        open={Boolean(anchorEl)}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <MenuItem onClick={() => handleShareClick(filteredQuiz._id)}>
          <Icon icon="solar:share-bold" width="24" height="24" />
          Share
        </MenuItem>

        {/* <Divider sx={{ borderStyle: "dashed" }} /> */}
        {/* <MenuItem
          onClick={() => handleDelete(filteredQuiz._id)}
          disabled={delLoading}
        >
          {confirmDelete === filteredQuiz._id ? (
            <Zoom in timeout={300}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  color: delLoading ? "disabled.main" : "success.main",
                  gap: 1,
                }}
              >
                <Icon icon="solar:check-circle-bold" width={24} height={24} />
                Confirm
              </Box>
            </Zoom>
          ) : (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                color: delLoading ? "disabled.main" : "error.main",
                gap: 1,
              }}
            >
              <Icon
                icon="solar:trash-bin-minimalistic-bold"
                width={24}
                height={24}
              />
              Delete
            </Box>
          )}
          {delLoading && confirmDelete === filteredQuiz._id && (
            <CircularProgress
              size={30}
              sx={{
                position: "absolute",
                top: 3,
                left: 5,
                zIndex: 1,
              }}
            />
          )}
        </MenuItem> */}
      </Menu>
    </>
  );
}
