import {
  Box,
  CssBaseline,
  autocompleteClasses,
  ThemeProvider,
  createTheme,
  linearProgressClasses,
  dividerClasses,
  menuItemClasses,
  svgIconClasses,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import { clearMessage } from "./actions/message";
import { ColorModeContext } from "./common/components/ColorModeContext";
import BoardAdmin from "./components/BoardAdmin";
import BoardModerator from "./components/BoardModerator";
import BoardUser from "./components/BoardUser";
import Home from "./components/Home";
import JoinWaitlist from "./components/JoinWaitlist";
import Login from "./components/Login";
import PageNotFound from "./components/PageNotFound";
import Register from "./components/Register";
import ResetPassword from "./components/ResetPassword";
import ViewStudyset from "./components/studysets/ViewStudyset";
import Courses from "./components/course/Courses";
import CreateCourseCard from "./components/course/Create";
import TakeCourse from "./components/course/TakeCourse";
import Dashboard from "./components/dashboard/Dashboard";
import AccountNav from "./components/profile/AccountNav";
import Profile from "./components/profile/Profile";
import Flashcards from "./components/studysets/flashcard/Flashcards";
import CreateQuizCard from "./components/studysets/quiz/Create";
import Quiz from "./components/studysets/quiz/Quiz";
import QuizList from "./components/studysets/quiz/Quizzes";
import Header from "./navbar/Header";
import MiniDrawer from "./sidebar/Sidebar";

const pxToRem = value => {
  return `${value / 16}rem`;
};

const responsiveFontSizes = ({ sm, md, lg }) => {
  return {
    "@media (min-width:600px)": {
      fontSize: pxToRem(sm),
    },
    "@media (min-width:900px)": {
      fontSize: pxToRem(md),
    },
    "@media (min-width:1200px)": {
      fontSize: pxToRem(lg),
    },
  };
};

const App = () => {
  const { isLoggedIn } = useSelector(state => state.auth);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [open, setOpen] = useState(true);
  const [mode, setMode] = useState("dark");
  const location = useLocation();
  const dispatch = useDispatch();

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode(prevMode => (prevMode === "light" ? "dark" : "light"));
      },
    }),
    []
  );

  useEffect(() => {
    if (["/login", "/register"].includes(location.pathname)) {
      dispatch(clearMessage());
    }
  }, [dispatch, location]);

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: mode,
          primary: {
            darker: "#004B50",
            lighter: "#C8FAD6",
            dark: "#007867",
            main: "#00A76F",
            light: "#5BE49B",
            contrastText: "#FFFFFF",
          },
          secondary: {
            main: "#8E33FF",
            light: "#C684FF",
            dark: "#5119B7",
            contrastText: "#FFFFFF",
          },
          error: {
            light: "#FFAC82",
            main: "#FF5630",
            dark: "#B71D18",
          },
          warning: {
            main: "#FFAB00",
          },
          iconButton: {
            main: "#919EAB",
          },
          disabled: {
            main: "rgba(145 158 171 / 0.24)",
          },
          success: {
            main: "#22C55E",
          },
          background: {
            default: mode === "dark" ? "#141A21" : "rgb(251, 251, 255)",
            paper: mode === "dark" ? "#1C252E" : "rgb(241, 245, 249)",
            neutral: mode === "dark" ? "#28323D" : "rgb(241, 245, 249)",
          },
          text: {
            secondary: mode === "dark" ? "#919EAB" : "#374151",
          },
        },
        typography: {
          fontFamily: "Public Sans",
          fontWeightRegular: 400,
          fontWeightMedium: 500,
          fontWeightSemiBold: 600,
          fontWeightBold: 700,
          h1: {
            fontWeight: 800,
            lineHeight: 80 / 64,
            fontSize: pxToRem(40),
            ...responsiveFontSizes({ sm: 52, md: 58, lg: 64 }),
          },
          h2: {
            fontWeight: 800,
            lineHeight: 64 / 48,
            fontSize: pxToRem(32),
            ...responsiveFontSizes({ sm: 40, md: 44, lg: 48 }),
          },
          h3: {
            fontWeight: 700,
            lineHeight: 1.5,
            fontSize: pxToRem(24),
            ...responsiveFontSizes({ sm: 26, md: 30, lg: 32 }),
          },
          h4: {
            fontWeight: 700,
            lineHeight: 1.5,
            fontSize: pxToRem(20),
            ...responsiveFontSizes({ sm: 20, md: 24, lg: 24 }),
          },
          h5: {
            fontWeight: 700,
            lineHeight: 1.5,
            fontSize: pxToRem(18),
            ...responsiveFontSizes({ sm: 19, md: 20, lg: 20 }),
          },
          h6: {
            fontWeight: 700,
            lineHeight: 28 / 18,
            fontSize: pxToRem(17),
            ...responsiveFontSizes({ sm: 18, md: 18, lg: 18 }),
          },
          subtitle1: {
            fontWeight: 600,
            lineHeight: 1.5,
            fontSize: pxToRem(16),
          },
          subtitle2: {
            fontWeight: 600,
            lineHeight: 22 / 14,
            fontSize: pxToRem(14),
          },
          body1: {
            lineHeight: 1.5,
            fontSize: pxToRem(16),
          },
          body2: {
            lineHeight: 22 / 14,
            fontSize: pxToRem(14),
          },
          caption: {
            lineHeight: 1.5,
            fontSize: pxToRem(12),
          },
          overline: {
            fontWeight: 700,
            lineHeight: 1.5,
            fontSize: pxToRem(12),
            textTransform: "uppercase",
          },
          button: {
            fontWeight: 700,
            lineHeight: 24 / 14,
            fontSize: pxToRem(14),
            textTransform: "unset",
          },
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                backgroundColor: "#454f5b",
                borderRadius: "8px",
              },
            },
          },
          MuiDivider: {
            styleOverrides: {
              root: {
                borderColor:
                  mode === "dark"
                    ? "rgba(145, 158, 171, 0.2)"
                    : "rgb(241, 245, 249)",
              },
            },
          },
          MuiAlert: {
            styleOverrides: {
              root: {
                borderRadius: "8px",
              },
            },
          },
          MuiButton: {
            styleOverrides: {
              contained: {
                boxShadow: "none",
              },
              outlined: {
                borderColor:
                  mode === "dark" ? "rgb(55, 65, 81)" : "rgb(229, 231, 235)",
                color: mode === "dark" ? "white" : "inherit",
                "&:hover": {
                  borderColor: mode === "dark" ? "white" : "rgb(241, 245, 249)",
                  backgroundColor: "inherit",
                },
              },
              root: {
                padding: "6px 12px",
                textTransform: "capitalize",
                borderRadius: "10px",
                fontWeight: 700,
              },
            },
          },
          MuiMenu: {
            styleOverrides: {
              list: {
                padding: "0px",
              },
              paper: {
                position: "absolute",
                minWidth: "148px",
                outline: "0px",
                backdropFilter: "blur(20px)",
                backgroundColor: "rgb(20, 26, 33)",
                backgroundImage:
                  "url(https://assets.minimals.cc/public/assets/core/cyan-blur.png), url(https://assets.minimals.cc/public/assets/core/red-blur.png)",
                backgroundRepeat: "no-repeat, no-repeat",
                backgroundPosition: "right top, left bottom",
                backgroundSize: "50%, 50%",
                padding: "4px",
                boxShadow:
                  "rgba(0, 0, 0, 0.24) 0px 0px 2px 0px, rgba(0, 0, 0, 0.24) -20px 20px 40px -4px",
                borderRadius: "10px",
                overflow: "inherit",
              },
            },
          },
          MuiTableCell: {
            styleOverrides: {
              root: {
                borderBottom: "1px dashed rgba(145, 158, 171, 0.2)",
                fontWeight: 500,
                color: mode === "dark" ? "white" : "inherit",
              },
            },
          },
          MuiMenuItem: {
            styleOverrides: {
              root: {
                display: "flex",
                alignItems: "center",
                gap: "8px",
                fontSize: "14px",
                borderRadius: "6px",
                padding: "6px 8px",
                margin: "0px 0px 4px",
                "&:hover": {
                  backgroundColor: "rgba(145 158 171 / 0.08)",
                },
                "&.Mui-selected": {
                  backgroundColor: "rgba(145 158 171 / 0.16)",
                  backgroundImage: "transparent",
                  "&:hover": {
                    backgroundColor: "rgba(145 158 171 / 0.16)",
                  },
                },
                "&:last-child": {
                  marginBottom: 0,
                },
              },
            },
          },
          MuiDialog: {
            styleOverrides: {
              paper: {
                borderRadius: "16px",
                backgroundImage: "none",
                backgroundColor: mode === "dark" && "#1C252E",
                boxShadow: "rgba(0, 0, 0, 0.24) -40px 40px 80px -8px",
              },
              backdrop: {
                backgroundColor: "rgba(28, 37, 46, 0.4)",
              },
            },
          },
          MuiDialogTitle: {
            styleOverrides: {
              root: {
                fontWeight: 600,
                fontSize: "1.125rem",
                padding: "24px 24px 16px",
              },
            },
          },
          MuiIconButton: {
            styleOverrides: {
              root: {
                color: mode === "dark" && "rgb(156, 163, 175)",
              },
            },
          },
          MuiAutocomplete: {
            styleOverrides: {
              root: ({ theme }) => ({
                [`& span.${autocompleteClasses.tag}`]: {
                  fontWeight: 600,
                  lineHeight: 22 / 14,
                  fontSize: theme.typography.pxToRem(14),
                  height: 24,
                  minWidth: 24,
                  lineHeight: "24px",
                  textAlign: "center",
                  padding: theme.spacing(0, 0.75),
                  color: theme.palette.text.secondary,
                  borderRadius: "16px",
                  backgroundColor: "rgba(145 158 171 / 0.16)",
                },
              }),
              paper: ({ theme }) => ({
                backdropFilter: "blur(20px)",
                WebkitBackdropFilter: "blur(20px)",
                backgroundColor: "rgb(20, 26, 33)",
                backgroundImage:
                  "url(https://assets.minimals.cc/public/assets/core/cyan-blur.png), url(https://assets.minimals.cc/public/assets/core/red-blur.png)",
                backgroundRepeat: "no-repeat, no-repeat",
                backgroundPosition: "right top, left bottom",
                backgroundSize: "50%, 50%",
                padding: theme.spacing(0.5),
                boxShadow:
                  "0 0 2px 0 rgba(0 0 0 / 0.24),-20px 20px 40px -4px rgba(0 0 0 / 0.24)",
                borderRadius: "10px",
              }),
              listbox: ({ theme }) => ({
                padding: 0,
                [`& .${autocompleteClasses.option}`]: {
                  lineHeight: 22 / 14,
                  fontSize: theme.typography.pxToRem(14),
                  padding: theme.spacing(0.75, 1),
                  borderRadius: "6px",
                  "&:not(:last-of-type)": { marginBottom: 4 },
                  [`&.${menuItemClasses.selected}`]: {
                    fontWeight: 600,
                    backgroundColor: "rgba(145 158 171 / 0.16)",
                    "&:hover": { backgroundColor: "rgba(145 158 171 / 0.08)" },
                  },
                },
                [`&.${autocompleteClasses.option}[aria-selected="true"]`]: {
                  backgroundColor: "rgba(145 158 171 / 0.16)",
                  "&:hover": { backgroundColor: "rgba(145 158 171 / 0.08)" },
                },
                [`&+.${dividerClasses.root}`]: {
                  margin: theme.spacing(0.5, 0),
                },
              }),
            },
            endAdornment: {
              [`& .${svgIconClasses.root}`]: { width: 18, height: 18 },
            },
          },
          MuiChip: {
            styleOverrides: {
              root: {
                margin: "3px",
                maxWidth: "calc(100% - 6px)",
                fontSize: "0.8125rem",
                height: "28px",
                backgroundColor: "rgba(145 158 171 / 0.16)",
                borderRadius: "8px",
                "&:hover": {
                  backgroundColor: "rgba(145 158 171 / 0.24)",
                },
              },
            },
          },
          MuiCard: {
            styleOverrides: {
              root: {
                borderRadius: "16px",
                backgroundImage: "none",
                boxShadow:
                  "rgba(0, 0, 0, 0.2) 0px 0px 2px 0px, rgba(0, 0, 0, 0.12) 0px 12px 24px -4px",
              },
            },
          },
          MuiDrawer: {
            styleOverrides: {
              paper: {
                backgroundColor: mode === "dark" && "#141A21",
                borderRight: "1px solid #1d242b",
              },
            },
          },

          MuiOutlinedInput: {
            styleOverrides: {
              root: {
                borderRadius: "10px",
                "& fieldset": {
                  color: "#9CA3AF",
                  borderColor:
                    mode === "dark"
                      ? "rgba(145 158 171 / 0.2)"
                      : "rgb(229, 231, 235)",
                },
              },
            },
          },
          MuiLinearProgress: {
            styleOverrides: {
              root: {
                height: 10,
                borderRadius: 5,

                [`& .${linearProgressClasses.bar}`]: {
                  borderRadius: 5,
                  backgroundColor: "rgb(0, 167, 111)",
                },
              },
            },
          },
        },
      }),
    [mode]
  );

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <Box sx={{ display: "flex" }}>
            <CssBaseline />
            {isLoggedIn && (
              <>
                <Header open={open} handleToggle={handleDrawerToggle} />
                <MiniDrawer
                  open={open}
                  setOpen={setOpen}
                  mobileOpen={mobileOpen}
                  setMobileOpen={setMobileOpen}
                  isClosing={isClosing}
                  setIsClosing={setIsClosing}
                />
              </>
            )}
            <ToastContainer
              hideProgressBar
              draggable
              theme="dark"
              toastStyle={{ backgroundColor: "rgb(28, 37, 46)" }}
            />
            <Box
              component="main"
              sx={{
                flexGrow: 1,
                mt: isLoggedIn && "72px",
              }}
            >
              <Routes>
                {isLoggedIn ? (
                  <>
                    <Route exact path="/dashboard" element={<Dashboard />} />
                    <Route
                      exact
                      path="/"
                      element={<Navigate to="/dashboard" />}
                    />
                    <Route
                      exact
                      path="/profile"
                      element={<Navigate to="/profile/settings" />}
                    />
                    <Route
                      exact
                      path="/profile/settings"
                      element={<Profile />}
                    />
                    <Route exact path="/profile/*" element={<AccountNav />} />
                    <Route exact path="/user" element={<BoardUser />} />
                    <Route exact path="/mod" element={<BoardModerator />} />
                    <Route exact path="/admin" element={<BoardAdmin />} />
                    <Route
                      exact
                      path="/reset-password"
                      element={<ResetPassword />}
                    />
                    <Route
                      exact
                      path="/generate/studyset"
                      element={<CreateQuizCard />}
                    />
                    <Route exact path="/courses" element={<Courses />} />
                    <Route
                      exact
                      path="/generate/course"
                      element={<CreateCourseCard />}
                    />
                    <Route
                      exact
                      path="/course/:courseId"
                      element={<TakeCourse />}
                    />
                    <Route exact path="/quiz/:quizId" element={<Quiz />} />
                    <Route
                      exact
                      path="/flashcard/:quizId"
                      element={<Flashcards />}
                    />
                    <Route exact path="/studysets" element={<QuizList />} />
                    <Route
                      exact
                      path="/login"
                      element={<Navigate to="/dashboard" />}
                    />
                    <Route
                      exact
                      path="/register"
                      element={<Navigate to="/dashboard" />}
                    />
                    <Route
                      exact
                      path="/"
                      element={<Navigate to="/dashboard" />}
                    />
                    {/* <Route exact path="/history" element={<History />} /> */}
                    {/* <Route exact path="/analytics" element={<Analytics />} /> */}
                    {/* <Route exact path="/page" element={<Page />} /> */}
                    <Route
                      exact
                      path="/studyset/:studysetId"
                      element={<ViewStudyset />}
                    />
                    <Route path="*" element={<PageNotFound />} />
                  </>
                ) : (
                  <>
                    <Route
                      exact
                      path="/join-waitlist"
                      element={<JoinWaitlist />}
                    />
                    <Route exact path="/" element={<Home />} />
                    <Route path="/register" element={<Register />} />
                    <Route path="/login" element={<Login />} />
                    {/* <Route path="*" element={<Navigate to="/login" />} /> */}
                  </>
                )}
              </Routes>
            </Box>
          </Box>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </LocalizationProvider>
  );
};

export default App;
