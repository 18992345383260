import { Box, ButtonBase, Typography, Tooltip } from "@mui/material";
import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function MenuItem({ open, linkName, name, icon, multiWord }) {
  const navigate = useNavigate();
  const [isOverflowing, setIsOverflowing] = useState(false);
  const textRef = useRef(null);

  useEffect(() => {
    if (textRef.current) {
      setIsOverflowing(
        textRef.current.scrollWidth > textRef.current.clientWidth
      );
    }
  }, [name, open]);

  const content = (
    <ButtonBase
      onClick={() => navigate(linkName)}
      sx={{
        display: "flex",
        ...(!open && {
          flexDirection: "column",
          gap: "6px",
          px: 2,
        }),
        cursor: "pointer",
        justifyContent: "start",
        width: "100%",
        backgroundColor:
          window.location.pathname === linkName
            ? "rgba(0, 167, 111, 0.08)"
            : "transparent",
        padding: 1.5,
        borderRadius: "8px",
        color:
          window.location.pathname === linkName
            ? "#5BE49B"
            : "rgb(145, 158, 171)",
        fontSize: open ? "0.875rem" : "0.625rem",
        minHeight: "44px",
        "&:hover": {
          backgroundColor:
            window.location.pathname === linkName
              ? "rgba(0, 167, 111, 0.16)"
              : "rgba(145, 158, 171, 0.08)",
        },
      }}
    >
      <Box
        sx={{
          width: "24px",
          height: "24px",
          mr: open && 1.5,
          display: "inline-flex",
          backgroundColor: "currentcolor",
          mask: icon,
        }}
      />
      <Typography
        ref={textRef}
        sx={{
          fontWeight: window.location.pathname === linkName ? 600 : 500,
          fontSize: open ? "0.875rem" : "0.625rem",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          overflow: "hidden",
        }}
      >
        {name}
      </Typography>
    </ButtonBase>
  );

  return isOverflowing && open ? (
    <Tooltip title={name} placement="right">
      {content}
    </Tooltip>
  ) : (
    content
  );
}
